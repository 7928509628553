import { ReactNode, useEffect } from 'react';
import { useLocalStorage } from '../hooks/use-local-storage';
import { UserContext, defaultUser } from '../contexts/user-context';
import { User } from '../types/user';

export type UserContextProviderProps = {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser, isStorageAvailable] = useLocalStorage<User>('KEY_USER', defaultUser);
  
  useEffect(() => {
    if (!isStorageAvailable) {
      console.warn('localStorage is not available. User settings and progress will not be saved between sessions.');
      // You could add code here to show a notification to the user
    }
  }, [isStorageAvailable]);
  
  const value = { 
    user, 
    setUser, 
    isStorageAvailable 
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};