import { useState, useEffect, Dispatch } from "react";

// Helper function to safely check localStorage availability
const isLocalStorageAvailable = (): boolean => {
  try {
    const testKey = "__storage_test__";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const useLocalStorage = <T>(key: string, initialValue: T): [T, Dispatch<React.SetStateAction<T>>, boolean] => {
  const storageAvailable = isLocalStorageAvailable();
  let storedValue: T | null = null;

  if (storageAvailable) {
    try {
      const storedJson = localStorage.getItem(key);

      if (storedJson !== null) {
        storedValue = JSON.parse(storedJson);
      }
    } catch (err) {
      try {
        localStorage.removeItem(key);
      } catch (e) {
        // Ignore removal errors
      }
    }
  }

  const [value, setValue] = useState<T>(storedValue ?? initialValue);

  useEffect(() => {
    if (storageAvailable) {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (e) {
        // Handle storage error (e.g., quota exceeded)
        console.warn('Failed to save to localStorage:', e);
      }
    }
  }, [key, value, storageAvailable]);

  // Return storage availability as third value
  return [value, setValue, storageAvailable];
}