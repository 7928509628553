import { createContext } from 'react';
import { User } from '../types/user';

export type UserContextData = {
  user: User;
  setUser: (user: User) => void;
  isStorageAvailable: boolean;
}

export const defaultUser: User = {
  id: 'invalid',
  history: [],
}

export const UserContext = createContext<UserContextData>({
  user: defaultUser,
  setUser: () => void 0,
  isStorageAvailable: false,
});
